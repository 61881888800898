(($) ->

	$("#home .gr-6").hover ->
		$(@).addClass('hover').siblings('.gr-6').removeClass 'hover'

	$("body, .form-close").click ->
		$(".button-popup.open").removeClass 'open'
		$("body").removeClass 'noflow'

	$(".popup-form").click (e) ->
		e.stopPropagation()

	$(".popup-button, .popup-menu").click (e) ->
		e.preventDefault()
		e.stopPropagation()

		popup = $(@).next '.button-popup'
		popup.addClass 'open'
		$("body").addClass 'noflow'

	$(".menu-hamburger, #mobile-menu .hamburger").click (e) ->
		$("#mobile-menu").toggleClass 'open'

	$(".testimonial-wrap").height $(".testimonial-item").first().outerHeight() + 32
	$(".testimonial-item:first").addClass 'active'
	$(".testimonial-nav .nav-item").click ->
		dir = $(@).data 'direction'
		active = $(".testimonial-item.active")
		switch dir
			when 'prev'
				if $(".testimonial-item:first").hasClass 'active'
					next = $(".testimonial-item:last")
				else
					next = active.prev '.testimonial-item'
			when 'next'
				if $(".testimonial-item:last").hasClass 'active'
					next = $(".testimonial-item:first")
				else
					next = active.next '.testimonial-item'
		
		active.fadeOut(300).removeClass 'active'
		next.fadeIn(300).addClass 'active'
		$(".testimonial-wrap").animate { height: next.outerHeight() + 32 }, 300

	$(".page-section--boxes").each ->
		maxHeight = 0

		$(".boxes-box", @).each ->
			maxHeight = if maxHeight > $(@).height() then maxHeight else $(@).height()
		.each ->
			$(@).height maxHeight

	$("iframe").width '100%'
	# $("iframe").each ->
		# if not $(@).closest('.video-wrap').length
		# 	$(@).wrap '<div class="video-wrap" />'

	# testimonialSliders = []
	# $(".section-testimonial").each ->
	# 	testimonialSliders.push setInterval ->
	# 			console.log 'next'
	# 			$(".testimonial-nav .nav-item--next").click()
	# 		, 5000

) jQuery